import React from 'react'
import { Step } from 'semantic-ui-react'


const StepExampleOrdered = props => {

  let one = props.global.stepsState.one
  let two = props.global.stepsState.two
  let three = props.global.stepsState.three
  let four = props.global.stepsState.four

  return (
    <>
    <h4>Pasos:</h4>
    <Step.Group ordered>
      <Step completed={one.completed} disabled={one.disabled} active={one.active}>
        <Step.Content>
          <Step.Title>Seleccionar</Step.Title>
          {/* <Step.Description>Cargar y confirmar que el archivo se haya cargado correctamente</Step.Description> */}
        </Step.Content>
      </Step>

      <Step completed={two.completed} disabled={two.disabled} active={two.active}>
        <Step.Content>
          <Step.Title>Revisar</Step.Title>
          {/* <Step.Description>Confirmar el archivo Carga Masiva FEMSA</Step.Description> */}
        </Step.Content>
      </Step>

      <Step completed={three.completed} disabled={three.disabled} active={three.active}>
        <Step.Content>
          <Step.Title>Enviar</Step.Title>
          {/* <Step.Description>Revisar validez del archivo</Step.Description> */}
        </Step.Content>
      </Step>

      <Step completed={four.completed} disabled={four.disabled} active={four.active}>
        <Step.Content>
          <Step.Title>Procesar</Step.Title>
          {/* <Step.Description>Procesar Archivo</Step.Description> */}
        </Step.Content>
      </Step>
    </Step.Group>
    </>
  )
}

export default StepExampleOrdered
