import React from 'react';
import { Message, Icon, Divider, Table, Button } from 'semantic-ui-react'


const Step3 = props => {

    const state = { ...props.global }

    // const errorMessage = {
    //     header: 'Lo sentimos, el archivo contiene errores',
    //     content: <p>Por favor verifique los errores mostrados a continuación, vaya al botón <b>Volver al Inicio</b> y corrija el archivo.</p>
    // }

    let errorMessage = {
        header: '',
        content: ''
    }

    if (state.errorData.processState === 1) {
        errorMessage = {
            header: 'Lo sentimos, el archivo contiene errores',
            content: <p>Por favor verifique los errores mostrados a continuación, utilice el botón <b>Volver al Inicio</b> y corrija el archivo.</p>
        }
    } else {
        errorMessage = {
            header: 'Lo sentimos, archivo procesado parcialmente con errores',
            content: <p>Si desea utilizar el mismo archivo recuerde excluir los items que no estan presentes en la lista de errores y evitar duplicidad en los datos, utilice el botón <b>Volver al Inicio</b> e inicie el proceso.</p> 
        }
    }

    return (
        <div style={{ width: 100 + "%" }}>
            {state.processing ? <Message icon>
                <Icon name='circle notched' loading />
                <Message.Content>
                    <Message.Header>Procesando archivo</Message.Header>
                    Estamos procesando su archivo para verificar que no hayan errores. Si existe algún error, el proceso se cancelara y se mostraran los errores obtenidos.
                </Message.Content>
            </Message> : null
            }
            {!state.processing && !state.errorProcessing ?
                <Message positive>
                    <Message.Header>Su archivo ha sido procesado satisfactoriamente</Message.Header>
                    <p>
                        Vaya al botón <b>Volver al Inicio</b> si desea procesar algún otro archivo.
                    </p>
                </Message> : null
            }
            {!state.processing && state.errorProcessing ?
                <div>

                    {
                        state.errorData.processState === 1 ?
                            <Message error>
                                <Message.Header>{errorMessage.header}</Message.Header>
                                {errorMessage.content}
                            </Message> :
                            state.errorData.processState === 2 ?
                                <Message warning>
                                    <Message.Header>{errorMessage.header}</Message.Header>
                                    {errorMessage.content}
                                </Message> 
                                :null
                    }

                    <Divider />
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Item</Table.HeaderCell>
                                <Table.HeaderCell>Error</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {(state.errorData.cargaMasivaItems && state.errorData.cargaMasivaItems.length > 0) ? state.errorData.cargaMasivaItems.map(data => {
                                return (
                                    <Table.Row negative key={data.itemId}>
                                        <Table.Cell>{data.itemId}</Table.Cell>
                                        <Table.Cell>{data.errorMessage}</Table.Cell>
                                    </Table.Row>
                                )
                            }) : null
                            }
                        </Table.Body>
                    </Table>
                </div>
                : null
            }
        </div>
    );
}

export default Step3;
