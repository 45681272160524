import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware, connectRouter } from 'connected-react-router'

import { createBrowserHistory } from 'history'
import { createLogger } from 'redux-logger'

//import signInUpReducer from "./SignInUp";
// import CounterReducer from "./Counter";
// import WeatherForecastsReducer from "./WeatherForecasts";
import GlobalReducer from "./Global";

const logger = createLogger({
  // ...options
  collapsed : true,
  timestamp : true,
  duration: true
});

export const history = createBrowserHistory()

export default function configureStore (history, initialState) {
  const reducers = {
    // counter : CounterReducer,
    // WeatherForecasts: WeatherForecastsReducer,
    global : GlobalReducer
  };

  const middleware = [
    thunk,
    routerMiddleware(history),
    logger
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];

  const isDevelopment = process.env.NODE_ENV === 'development';
  
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
