
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { setSession, updateSteps } from '../store/Global/actions';

const CheckToken = props => {

    const { match: { params } } = props;
    const { tokenId, idOperatorHash } = params;

    console.log('Params ==>', params);

    const state = props.global
    let ref = window.location.origin;
    state.locationRef = ref

    if (!tokenId || !idOperatorHash) {
        props.history.push('/SignIn');
    } else {
        fetch(`${state.locationRef}/api/Auth/GetToken/${tokenId}/${idOperatorHash}`)
            .then(function (response) {
                if (response.status !== 200) {
                     Object.assign(state,
                        {
                            confirmMessage: `Error obteniendo credenciales, por favor conectese de nuevo. - ${response.statusText}`,
                            confirm: true,
                        })
                    props.updateSteps(state)
                    props.history.push('/SignIn');
                    return false
                }
                return response.json();
            })
            .then(data => {

                if (!data) {
                    props.history.push('/SignIn')
                } else {
                    data['idOperatorHash'] = idOperatorHash;
                    state.tokenInfo = data
                    if (!(Object.keys(state.tokenInfo).length === 0)) {
                        props.history.push('/Home')
                    }
                }
            })
            .catch(error => {
                console.error('Error Calling API ==>', error)
                props.history.push('/SignIn');
            });
    }


    return (
        <div>
            {/* <h4>ID: {tokenId} Hash {idOperatorHash}</h4> */}
        </div>
    )

}

const mapDispatchToProps = dispatch => ({
    updateSteps: (data) => dispatch(updateSteps(data)),
    setSession: (sessionId) => dispatch(setSession(sessionId)),
})

const mapStateToProps = state => {
    return {
        global: state.global
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(CheckToken))

