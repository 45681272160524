import React from 'react';
import { Button, Icon } from 'semantic-ui-react'

const ButtonController = props => {

    const state = { ...props.global }

    const postData = () => {
        fetch(`${state.locationRef}/api/processdata/${state.sessionId}/${state.tokenInfo.idOperatorHash}`,
            {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(state.fileData)
            })
            .then(function (response) {
                if (response.status !== 200) {
                    Object.assign(state,
                        {
                            errorProcessing: true,
                            processing: false,
                        })
                    Object.assign(state.stepsState,
                        {
                            four: {
                                active: true,
                                completed: false,
                                disabled: false,
                            }
                        })
                    props.updateSteps(state)
                } else {
                    Object.assign(state,
                        {
                            errorProcessing: false,
                            processing: false,
                        })
                    Object.assign(state.stepsState,
                        {
                            four: {
                                active: true,
                                completed: true,
                                disabled: false,
                            }
                        })

                    props.updateSteps(state)
                }
                return response.json();
            })
            .then(data => {
                console.log('Data ===>',data);
                Object.assign(state,
                    {
                        errorData: data.processResult,
                    })
    
                props.updateSteps(state)
            })
            .catch(error => {
                console.error('Error ==>', error);
            });

    }

    const handleForwardClick = () => {
        if (state.step === 1) {
            Object.assign(state,
                {
                    step: 2,
                })
            props.updateSteps(state)
        }
    }

    const handleBackClick = () => {
        if (state.step === 2) {
            Object.assign(state,
                {
                    step: 1,
                })

            Object.assign(state.stepsState,
                {
                    one: {
                        active: true,
                        completed: true,
                        disabled: false,

                    },
                    two: {
                        active: false,
                        completed: false,
                        disabled: false,

                    }
                })

            props.updateSteps(state)
        } else if (state.step === 3) {
            Object.assign(state,
                {
                    step: 2,
                })

            props.updateSteps(state)
        } else if (state.step === 4) {
            Object.assign(state,
                {
                    step: 3,
                })

            props.updateSteps(state)
        }
    }

    const goToNext = () => {
        Object.assign(state.stepsState,
            {
                two: {
                    active: false,
                    completed: true,
                    disabled: false,

                },
                three: {
                    active: true,
                    completed: false,
                    disabled: false,

                }
            })
        Object.assign(state,
            {
                fileValidated: true,
                confirm: !state.confirm,
                confirmMessage: 'Archivo confirmado correctamente.'
            })
        props.updateSteps(state)
    }

    const initProcess = () => {
        if (state.step === 2) {
            Object.assign(state,
                {
                    step: 3,
                })

            props.updateSteps(state)
            postData();
        }
    }

    const goToInit = () => {
        Object.assign(state.stepsState,
            {
                one: {
                    active: true,
                    completed: false,
                    disabled: false,
                },
                two: {
                    active: false,
                    completed: false,
                    disabled: true,
                },
                three: {
                    active: false,
                    completed: false,
                    disabled: true,
                },
                four: {
                    active: false,
                    completed: false,
                    disabled: true,
                }
            })
        Object.assign(state,
            {
                step: 1,
                fileLoaded: [],
                fileData: [],
                fileSaved: false,
                fileValidated: false,
                processing: true,
                errorProcessing: false
            })
        props.updateSteps(state)
    }

    return (
        <div style={{ paddingBottom: 50 }}>
            {
                props.global.step === 2 ?
                    <Button animated size='mini' floated='left' compact onClick={() => handleBackClick()}>
                        <Button.Content visible>Anterior</Button.Content>
                        <Button.Content hidden>
                            <Icon name='arrow left' />
                        </Button.Content>
                    </Button> : null
            }
            {state.step === 1 ?
                <div>
                    <Button animated positive={state.stepsState.two.completed} disabled={!state.stepsState.two.completed} size='mini' floated='right' compact onClick={() => handleForwardClick()}>
                        <Button.Content visible>Siguiente</Button.Content>
                        <Button.Content hidden>
                            <Icon name='arrow right' />
                        </Button.Content>
                    </Button>
                    {state.fileData.length > 0 ?
                        <Button animated='vertical' size='mini' floated='right' compact positive={state.fileValidated} onClick={() => !state.fileValidated ? goToNext() : null}>
                            <Button.Content visible>Confirmar Archivo</Button.Content>
                            <Button.Content hidden>
                                <Icon name='checkmark' />
                            </Button.Content>
                        </Button> : null
                    }
                </div> : null
            }
            {state.step === 2 && state.stepsState.three.completed ?
                <Button animated positive={state.stepsState.two.completed} disabled={!state.stepsState.two.completed} size='mini' floated='right' compact onClick={() => initProcess()}>
                    <Button.Content visible>Procesar</Button.Content>
                    <Button.Content hidden>
                        <Icon name='save outline' />
                    </Button.Content>
                </Button> : null
            }
            {state.step === 3 ?
                <div>
                    {state.errorProcessing ?
                        <Button animated negative size='mini' floated='left' compact onClick={() => goToInit()}>
                            <Button.Content visible>Volver al inicio</Button.Content>
                            <Button.Content hidden>
                                <Icon name='arrow left' />
                            </Button.Content>
                        </Button> : null
                    }
                    {state.stepsState.four.completed && !state.errorProcessing ?
                        <Button animated positive size='mini' floated='right' compact onClick={() => goToInit()}>
                            <Button.Content visible>Volver al inicio</Button.Content>
                            <Button.Content hidden>
                                <Icon name='arrow right' />
                            </Button.Content>
                        </Button> : null
                    }
                </div> : null
            }
        </div>
    );
}

export default ButtonController;
