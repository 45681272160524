import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Button, Divider, Form, Container, Header, Input, Icon } from 'semantic-ui-react'
import { setSession, updateSteps } from '../store/Global/actions';
import Confirm from "./Confirm";

class SignIn extends Component {

    constructor(props) {
        super(props)

        this.state = {
            passwordShower: false,
            loading: false,
            userName: '',
            password: ''
        }

        this.reduxState = this.props.global;
        let ref = window.location.origin;
        this.reduxState.locationRef = ref

        this.onChangeData = this.onChangeData.bind(this)
        this.showPassword = this.showPassword.bind(this)
        this.doLogin = this.doLogin.bind(this)
    }

    showPassword() {
        this.setState({
            passwordShower: !this.state.passwordShower
        })
    }

    onChangeData(event) {
        this.setState({
            [event.target.id]: event.target.value,
        })
    }

    doLogin() {
        this.setState({
            loading: true
        })

        const self = this

        fetch(`${this.reduxState.locationRef}/api/Auth/Login/${this.state.userName}/${this.state.password}`)
            .then(function (response) {
                if (response.status !== 200) {
                    self.setState({
                        loading: false
                    });
                    Object.assign(self.reduxState,
                        {
                            confirmMessage: `Error de login, por favor intente de nuevo. - ${response.statusText}`,
                            confirm: true,
                        })
                    self.props.updateSteps(self.reduxState)
                    self.props.history.push('/SignIn');
                    return false
                }
                return response.json();
            })
            .then(data => {
                self.reduxState.tokenInfo = data
                if (!(Object.keys(self.reduxState.tokenInfo).length === 0)) {
                    self.setState({
                        loading: false
                    });
                    self.props.history.push('/Home');
                }
            })
            .catch(error => {
                self.setState({
                    loading: false
                })
                console.error('Error ==>', error);
            });
    }

    render() {
        return (
            <Container text textAlign="center">
                <Confirm {...this.props} message={this.reduxState.confirmMessage} />
                <div style={{ paddingTop: 25+'%' }}>
                <Header as='h2'>Conectarse a Carga Masiva</Header>
                    <Divider />
                    <Divider hidden />
                    <div style={{ margin: 'auto', width: 50 + '%', height: 150 }}>
                        <Form size="mini">
                            <Form.Field>
                                <input
                                    id="userName"
                                    onChange={this.onChangeData}
                                    placeholder='Usuario'
                                    type="text"
                                    value={this.state.userName} />
                            </Form.Field>
                            <Form.Field>
                                <Input
                                    id="password"
                                    onChange={this.onChangeData}
                                    type={this.state.passwordShower ? 'text' : 'password'}
                                    icon={<Icon onClick={() => this.showPassword()} name={this.state.passwordShower ? 'eye' : 'eye slash'} link disabled={this.state.password === ''} />}
                                    placeholder='Contraseña'
                                    value={this.state.password} />
                            </Form.Field>
                            <Button size="mini" positive type='submit' loading={this.state.loading} onClick={() => this.doLogin()}>Login</Button>
                        </Form>
                    </div>
                    <Divider />
                </div>
            </Container>
        );
    }

}

const mapDispatchToProps = dispatch => ({
    updateSteps: (data) => dispatch(updateSteps(data)),
    setSession: (sessionId) => dispatch(setSession(sessionId)),
})

const mapStateToProps = state => {
    return {
        global: state.global
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(withRouter(SignIn))



