import React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import SignIn from './components//SignIn';
import CheckToken from './components//CheckToken';
export default () => (
  <Layout>
    <Route exact path='/' component={CheckToken} />
    <Route exact path='/:tokenId/:idOperatorHash' component={CheckToken} />
    <Route exact path='/SignIn' component={SignIn} />
    <Route exact path='/Home' component={Home} />
  </Layout>
);
