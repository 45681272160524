import React from 'react';
import { Button, Modal } from 'semantic-ui-react'

const Confirm = props => {

    const state = { ...props.global }

    const confirmHandler = () => {
        Object.assign(state,
            {
                confirm: !state.confirm
            })
        props.updateSteps(state)
    }

    return (
        <Modal size='tiny' open={state.confirm} onClose={() => confirmHandler()}>
            <Modal.Header>Información</Modal.Header>
            <Modal.Content>
                <p>{state.confirmMessage}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button size="tiny" positive icon='checkmark' labelPosition='right' content='Ok' onClick={() => confirmHandler()} />
            </Modal.Actions>
        </Modal>
    )
};

export default Confirm
