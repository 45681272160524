import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import 'filepond/dist/filepond.min.css';
import { Divider, Button, Icon } from 'semantic-ui-react'
import StepOrdered from "./StepOrdered";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import ButtonController from "./ButtonController"
import Confirm from "./Confirm";
import { setSession, updateSteps } from '../store/Global/actions';

const Home = props => {

  const state = props.global;
  let ref = window.location.origin;
  state.locationRef = ref

  if ((Object.keys(state.tokenInfo).length === 0)) {
    props.history.push('/signIn');
  }


  return (
    <div>
      <Confirm {...props} message={state.confirmMessage} />
      <div style={{ height: 70, display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 1 }}>
          <h3>Carga Masiva Casos Femsa</h3>
        </div>
        <div style={{ width: 30 + '%', textAlign: 'center' }}>
          {/* <p><strong>Sesión Id: </strong>{state.sessionId}</p>
          <p><strong>Operador Nro: </strong>{state.tokenInfo.idOperatorHash}</p> */}
          <Button size='mini' color='blue' icon labelPosition='right'>
            <a style={{ textDecoration: 'none', color: '#FFF' }} href="/plantilla/PlantillaCargaMasiva.xlsx" download>Click para descargar Plantilla</a>
            <Icon name='file excel outline' />
          </Button>
        </div>
      </div>
      <Divider />
      <div style={{ textAlign: 'center' }}>
        <StepOrdered {...props}></StepOrdered>
      </div>
      <Divider />
      <div>
        {
          state.step === 1 ?
            <Step1 {...props}></Step1> : state.step === 2 ?
              <Step2 {...props}></Step2> : state.step === 3 ?
                <Step3 {...props}></Step3> : null
        }
      </div>
      <Divider />
      <ButtonController {...props}></ButtonController>

    </div>
  )
};

const mapDispatchToProps = dispatch => ({
  updateSteps: (data) => dispatch(updateSteps(data)),
  setSession: (sessionId) => dispatch(setSession(sessionId)),
})

const mapStateToProps = state => {
  return {
    global: state.global
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Home))

