import * as t from './action-types';
import * as uuid from "uuidv4";

const sessionId = uuid();

export const initialState = {
  locationRef: '',
  sessionId: sessionId,
  tokenInfo: {},
  stepsState: {
    one: {
      active: true,
      completed: false,
      disabled: false,
    },
    two: {
      active: false,
      completed: false,
      disabled: true
    },
    three: {
      active: false,
      completed: false,
      disabled: true
    },
    four: {
      active: false,
      completed: false,
      disabled: true
    },
  },
  fileLoaded: [],
  fileData: [],
  fileValidated: false,
  fileSaved: false,
  step: 1,
  confirm: false,
  confirmMessage: '',
  processing: true,
  errorProcessing: false,
  errorData: [],
  // todo: add initial state
};

/**
 * @param  {Object} state  - current redux state
 * @param  {Object} action - current redux action
 * @return {Object}        - next redux state after action has been applied
 */


export default function (state = initialState, action) {
  switch (action.type) {
    case t.SET_SESSIONID:
      return {
        ...state,
        ...action.payload,
      }
    case t.UPDATE_STEPS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state;
  }
}
