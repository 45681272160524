import React from 'react';
import { Table } from 'semantic-ui-react'
import ReactDataGrid from 'react-data-grid';
import { FilePond } from 'react-filepond';
import readXlsxFile from 'read-excel-file';
import {FilePondLanguage} from './Language';

const Step1 = props => {

    const state = { ...props.global };
    let message = ''

    const schema = {
        'Cod Cliente': {
            prop: 'CodCliente',
            type: Number,
            required: true
        },
        'Cod Planteamiento': {
            prop: 'CodPlanteamiento',
            type: Number,
            required: true
        },
        'Tipo Equipo': {
            prop: 'TipoEquipo',
            type: String,
            required: true,
            maxLength: 50
        },
        'Descripcion del Problema': {
            prop: 'Verbatim',
            type: String,
            required: true,
            maxLength: 100
        },
    }

    const getData = (file) => {
        const fileExt = file ? file.name.slice(file.name.length - 4, file.name.length) : null
        if (fileExt === '.xls' || fileExt === 'xlsx') {
            let newRows = []
            readXlsxFile(file, { schema }).then(({ rows, errors }) => {
                if (errors.length === 0 && rows.length <= 100) {
                    Object.assign(state,
                        {
                            confirm: false,
                        })
                    props.updateSteps(state)
                    let number = 0
                    rows.forEach(element => {
                        number++
                        newRows.push({
                            ItemId: number,
                            ...element,
                        })
                    });
                    gettInfo(newRows, file)
                    // writeJsonFile('./',{foo:true})
                } else {

                    if (rows.length > 100) {
                        message = 'El archivo no excede el límite maximo permitido por proceso (100).'
                    } else {
                        message = 'El archivo no contiene la informacion en los formatos requeridos, por favor verifique o descargue la Plantilla usando el boton Descargar Plantilla.'
                    }

                    Object.assign(state,
                        {
                            confirmMessage: message,
                            confirm: true,
                            fileSaved: false,
                            fileLoaded: [],
                            fileData: [],
                        })
                    props.updateSteps(state)

                }
            })
        } else {
            message = 'Existe un error el formato del archivo, asegurese que sea un archivo .xls, .xlsx o descargue la Plantilla usando el boton Descargar Plantilla.'

            Object.assign(state,
                {
                    confirmMessage: message,
                    confirm: true,
                    fileSaved: false,
                    fileLoaded: [],
                    fileData: [],
                })
            props.updateSteps(state)
        }
    }

    const gettInfo = (data, file) => {
        let files = [file]
        Object.assign(state,
            {
                fileLoaded: files,
                fileSaved: true,
                fileData: data,
            })
        props.updateSteps(state)
    }

    const removedata = () => {
        Object.assign(state.stepsState,
            {
                one: {
                    active: true,
                    completed: false,
                    disabled: false,
                },
                two: {
                    active: false,
                    completed: false,
                    disabled: true,

                },
                three: {
                    active: false,
                    completed: false,
                    disabled: true,
                }
            })
        Object.assign(state,
            {
                fileLoaded: [],
                fileData: [],
                fileSaved: false,
                fileValidated: false,
            })
        props.updateSteps(state)
    }

    return (
        <div style={{ width: 100 + "%" }}>
            <div>
                <FilePond
                labelFileProcessing={"....."}
                labelIdle={`Arrastar y soltar el archivo o <span class="filepond--label-action">Buscar</span>.`}
                    files={state.fileLoaded}
                    allowMultiple={false}
                    onremovefile={() => {
                        removedata()
                    }}
                    onaddfile={(error, file) => {
                        if (file) {
                            getData(file.source)
                            Object.assign(state.stepsState,
                                {
                                    one: {
                                        active: false,
                                        completed: true,
                                        disabled: false,
                                    },
                                    two: {
                                        active: true,
                                        completed: false,
                                        disabled: false,
                                    }
                                })
                            props.updateSteps(state)
                        }
                    }}
                    maxFiles={1}
                    oninit={null}
                    labelTapToCancel="Click para Cancelar"
                    labelTapToUndo="Click para Deshacer"
                    labelButtonRemoveItem={"Remover"}
                />
            </div>
            <div style={{ maxHeight: 300, overflowY: 'scroll' }}>
                {state.fileData.length > 0 ?

                    <Table celled padded='very'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={1}>Item</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Cod Cliente</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Cod Planteamiento</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Tipo Equipo</Table.HeaderCell>
                                <Table.HeaderCell width={7}>Descripción del Problema</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {state.fileData.map(data => {
                                return (
                                    <Table.Row key={data.ItemId}>
                                        <Table.Cell>{data.ItemId}</Table.Cell>
                                        <Table.Cell>{data.CodCliente}</Table.Cell>
                                        <Table.Cell>{data.CodPlanteamiento}</Table.Cell>
                                        <Table.Cell>{data.TipoEquipo}</Table.Cell>
                                        <Table.Cell>{data.Verbatim}</Table.Cell>
                                    </Table.Row>
                                )
                            })
                            }
                        </Table.Body>
                    </Table>
                    : null
                }
            </div>

        </div>
    );
}

export default Step1;
