import React from 'react';
import { FilePond } from 'react-filepond';


const Step2 = props => {

    const state = { ...props.global }

    const handleOnLoad = () => {

        Object.assign(state.stepsState,
            {
                three: {
                    active: false,
                    completed: true,
                    disabled: false,
                },
                four: {
                    active: true,
                    completed: false,
                    disabled: false,
                }
            })

        props.updateSteps(state)
    }

    const removedata = () => {
        Object.assign(state.stepsState,
            {
                one: {
                    active: true,
                    completed: false,
                    disabled: false,
                },
                two: {
                    active: false,
                    completed: false,
                    disabled: true,
                },
                three: {
                    active: false,
                    completed: false,
                    disabled: true,
                }
            })
        Object.assign(state,
            {
                step: 1,
                fileLoaded: [],
                fileData: [],
                fileSaved: false,
                fileValidated: false,
            })
        props.updateSteps(state)
    }


    return (
        <FilePond
            files={props.global.fileLoaded}
            allowMultiple={false}
            onremovefile={() => {
                removedata()
            }}
            maxFiles={1}
            oninit={null}
            server={
                {
                    url: `api/upload/${props.global.sessionId}`,
                    process: {
                        onload: (fileId) => handleOnLoad(fileId),
                        onerror: (error) => console.log(error),
                        ondata: null,
                    }
                }
            }
        />
    );
}

export default Step2;
